<template>
  <div class="chat-messages-item">
    <div class="top">
      <div class="name">
        {{ message.creatorDepartmentName }} - {{ message.creatorName }}
      </div>
      <div class="date">{{ message.createdOn | formattingDateMessage }}</div>
      <div
        class="indicator-new"
        :style="{ 'background-color': message.messageColor }"
      ></div>
    </div>
    <div class="bottom">{{ message.messageContent }}</div>
  </div>
</template>

<script>
export default {
  props: ['message']
}
</script>
