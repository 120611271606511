<template>
  <div class="chat">
    <q-page-container>
      <q-page>
        <div class="row">
          <div class="col-3 q-page-left">
            <div class="title">Projets</div>
            <div class="chat-list">
              <div v-if="getLastProjects.length">
                <ChatListItem
                  v-for="(project, index) in getLastProjects"
                  :class="{
                    active: activeProjectId
                      ? activeProjectId === project.projectId
                      : index === 0
                  }"
                  :key="project.projectId"
                  :project="project"
                  @click.native="updateChat(project.projectId, true)"
                />
              </div>
              <LoadingOrEmpty v-else :loading="getLoadingLastProjects" />
            </div>
          </div>
          <div class="col-9 q-page-right">
            <div class="chat-messages" :style="{ height: calc() }">
              <div v-if="Object.keys(getMessagesByProjectId).length">
                <div
                  v-for="(value, name, index) in getMessagesByProjectId"
                  :key="index"
                >
                  <span class="line-date">
                    <span>{{ name }}</span>
                  </span>
                  <ChatMessagesItem
                    v-for="(message, index) in value"
                    :key="index"
                    :message="message"
                  />
                </div>
              </div>
              <LoadingOrEmpty
                v-else
                :loading="getLoadingByProjectId"
                :custom-empty="true"
              >
                <template v-slot:customEmpty>
                  <div class="no-messages">Aucun message pour ce projet</div>
                </template>
              </LoadingOrEmpty>
            </div>
            <div class="chat-messages-actions">
              <q-form @submit="onSubmit" class="q-gutter-md">
                <q-input
                  square
                  outlined
                  bottom-slots
                  v-model="message"
                  placeholder="Rédiger un message"
                  @keydown.enter.exact.prevent="onSubmit"
                  @keydown.alt.enter.exact="newLine"
                  @keyup="keyUp"
                  type="textarea"
                />
                <q-btn
                  class="btn-send-message"
                  :disable="
                    !message.trim().length || getIsSendingMessage || delay
                  "
                  type="submit"
                />
              </q-form>
              <q-btn
                class="btn-white"
                icon-right="trending_flat"
                label="Voir le projet"
                @click="openProject()"
              />
            </div>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import ChatListItem from '@/components/chat/ChatListItem.vue'
import ChatMessagesItem from '@/components/chat/ChatMessagesItem.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MainChat',
  components: {
    LoadingOrEmpty,
    ChatMessagesItem,
    ChatListItem
  },
  computed: {
    ...mapGetters('projects', [
      'getLastProjects',
      'getLoadingLastProjects',
      'getErrorLastProjects'
    ]),
    ...mapGetters('messages', [
      'getMessagesByProjectId',
      'getLoadingByProjectId',
      'getErrorByProjectId',
      'getIsSendingMessage',
      'getErrorSendingMessage'
    ])
  },
  data() {
    return {
      message: '',
      activeProjectId: null,
      delay: false,
      defaultHeight: 80,
      maxHeight: 388
    }
  },
  methods: {
    ...mapActions('projects', ['fetchLastProjects']),
    ...mapActions('messages', [
      'fetchMessagesByProjectId',
      'sendMessageToProject'
    ]),
    newLine() {
      this.message += '\n'

      setTimeout(() => {
        this.resize()
        this.scrollBottom('textarea')
      }, 0)
    },
    resize() {
      const textarea = document.querySelector('textarea')
      const chatActions = document.querySelector('.chat-messages-actions')
      const form = chatActions.querySelector('form')

      if (textarea.scrollHeight + 40 >= this.maxHeight) {
        textarea.style.overflowY = 'scroll'
        chatActions.style.height = 364 + 'px'
        form.style.height = 322 + 'px'
        textarea.style.height = 318 + 'px'
        this.defaultHeight = 364
        return
      }

      textarea.style.overflowY = 'hidden'
      textarea.style.height = '1px'
      this.defaultHeight = textarea.scrollHeight + 40
      chatActions.style.height = 40 + textarea.scrollHeight + 'px'
      form.style.height = textarea.scrollHeight + 'px'
      textarea.style.height = textarea.scrollHeight + 'px'

      setTimeout(() => this.scrollBottom(), 0)
    },
    async onSubmit() {
      if (this.getIsSendingMessage || this.delay) return

      if (this.message && this.message.trim().length) {
        const projectId = this.getProjectId()
        await this.sendMessageToProject({
          projectId,
          message: this.message.trim()
        })
        this.updateChat(projectId)
        setTimeout(() => this.reset(), 0)
      }
    },
    reset() {
      const textarea = document.querySelector('textarea')
      const chatActions = document.querySelector('.chat-messages-actions')
      const form = chatActions.querySelector('form')

      this.defaultHeight = 80
      textarea.style.height = 36 + 'px'
      form.style.height = 40 + 'px'
      chatActions.style.height = this.defaultHeight + 'px'
      textarea.style.overflowY = 'hidden'
    },
    calc() {
      return window.innerHeight - 86 - this.defaultHeight + 'px'
    },
    async updateChat(projectId, update = false) {
      this.message = ''
      this.activeProjectId = projectId
      await this.fetchMessagesByProjectId({ projectId, update })
      this.scrollBottom()
      setTimeout(() => this.reset(), 0)
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    scrollBottom(place = '.chat-messages') {
      const chat = this.$el.querySelector(place)
      chat.scrollTop = chat.scrollHeight
    },
    getProjectId() {
      return this.activeProjectId
        ? this.activeProjectId
        : this.getLastProjects[0].projectId
    },
    openProject() {
      const projectId = this.getProjectId()
      this.$router.push(`/project/${projectId}`)
    },
    keyUp(e) {
      if (e.keyCode === 16 || e.keyCode === 18) {
        this.delay = true
        setTimeout(() => (this.delay = false), 500)
      }
    }
  },
  watch: {
    getErrorLastProjects: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorSendingMessage: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorByProjectId: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    message: function() {
      this.resize()
    }
  },
  async mounted() {
    const { projectId } = this.$route.query
    if (projectId) {
      this.activeProjectId = projectId
    }
    await this.fetchLastProjects()

    if (projectId) {
      await this.fetchMessagesByProjectId({ projectId })
    } else {
      await this.fetchMessagesByProjectId({
        projectId: this.getLastProjects[0].projectId
      })
    }

    this.scrollBottom()
  }
}
</script>
