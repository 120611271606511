<template>
  <div class="chat-list-item">
    <div
      :class="['left']"
      :style="{ 'background-color': project.projectColor }"
    ></div>
    <div class="right">
      <div class="name">{{ project.projectName }}</div>
      <div class="description">{{ project.projectDescription }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
